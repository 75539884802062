<template>
  <div class="animated fadeIn">
    <loading v-if="vLoading" />
    <v-col cols="12">
      <v-card-title style="background-color: transparent !important">
        <h3>اطلاعات {{ label }}</h3>
      </v-card-title>
      <v-tabs
        :class="type == 'outClinic' ? 'width-tab' : ''"
        fixed-tabs
        background-color="transparent"
        v-model="tab"
      >
        <v-tab class="tab-title" style="max-width: 100% !important">
          نمایش اطلاعات سرویس
        </v-tab>
        <v-tab v-if="type != 'outClinic'" class="tab-title">
          اطلاعات و درخواست های ثبت شده</v-tab
        >
      </v-tabs>
      <v-card class="br-card tab-item">
        <v-card-text>
          <div>
            <v-card>
              <v-tabs-items v-model="tab">
                <!-- نمایش اطلاعات خدمت -->
                <v-tab-item>
                  <v-card class="pa-2">
                    <v-row>
                      <v-col>
                        <v-select
                          label="وضعیت"
                          v-model="service.status"
                          :items="states"
                          item-text="text"
                          item-value="value"
                          outlined
                          dense
                        ></v-select>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <v-text-field
                          label="عنوان"
                          v-model="service.name"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col
                        v-if="type == 'clinic'"
                        cols="12"
                        sm="12"
                        md="6"
                        lg="6"
                        xl="6"
                      >
                        <v-select
                          label="شیوه ارائه خدمت"
                          v-model="service.type"
                          :items="serviceTypes"
                          item-text="text"
                          item-value="value"
                          menu-props="auto"
                          hide-details
                          outlined
                          dense
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="mt-4">
                        <vuetify-money
                          v-model="service.cost"
                          label="قیمت(ریال)"
                          :valueWhenIsEmpty="whenIsEmpty"
                          :options="vMoneyOptions"
                          type="number"
                          outlined
                          dense
                        />
                      </v-col>
                    </v-row>
                    <v-row v-if="type == 'clinic'">
                      <v-col>
                        <vuetify-money
                          v-model="service.expense"
                          label="هزینه(ریال)"
                          :valueWhenIsEmpty="whenIsEmpty"
                          :options="vMoneyOptions"
                          type="number"
                          outlined
                          dense
                        />
                      </v-col>
                    </v-row>
                    <v-row v-if="type == 'clinic'">
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <vuetify-money
                          v-model="service.protectiveExpense"
                          label="هزینه اقلام حفاظتی(ریال)"
                          :valueWhenIsEmpty="whenIsEmpty"
                          :options="vMoneyOptions"
                          type="number"
                          outlined
                          dense
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <v-text-field
                          label="سهم پزشک(درصد)"
                          v-model="service.docPercent"
                          type="number"
                          :rules="[percent]"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row v-if="type == 'clinic' || type == 'dental'">
                      <v-col class="pt-0">
                        <v-checkbox
                          v-model="service.isSelectableByPatient"
                          color="primary"
                        >
                          <template v-slot:label>
                            <span class="mt-2"
                              >امکان انتخاب این سرویس توسط بیمار وجود
                              دارد.</span
                            >
                          </template>
                        </v-checkbox>

                        <div
                          v-if="service.isSelectableByPatient"
                          class="d-flex align-end"
                        >
                          <div
                            class="img-box pointer"
                            @click="$refs.profileImage.$refs.input.click()"
                          >
                            <img
                              height="150"
                              width="150"
                              class="img"
                              :src="
                                service.fileUrl
                                  ? service.fileUrl
                                  : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAREAAAC4CAMAAADzLiguAAAANlBMVEXp7vG6vsHs8fS2ur3c4eTU2dzm6u3P1Ne4vL/u8/a4vL67v8G0ubzDx8rY3eDEyMvh5unKz9Izr04MAAADb0lEQVR4nO2c63KrIBRGFY1CY4x5/5c93nKiICZGGOvuWj86adowYc0HWxgxSQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAOC3oiJwdJ/2oJr6Epy6Sc4qxeTXKtNPfoYfP9NXDj//f0xfv97oX2X6cU4l5pGl6TWNgdbF0b37AnPXUWwMVEd37wvqLKKQNnzm6A5uxcRMSEuWH93DrTRV/8XbaVBnQdFj9u4nm13Vpc+ILk3wy5FCn3LYqHL43hG+9ti0PqmRCNGO2HRMVJlGNqIx8mpakpEQyzRBRlSSd+u0vT0RY8Tkt6rq1mnXcl9fpBjp130DOt2Vk8HI9exG1G16VV81u5qWkBF7Ibxn6SrDSF5ZC7UdqxIRRoyzcZR9P25EGCnsiLRLwK87JMGIqt3NkjdL15VdQxFGSkfIm+v7Irt7jUmovm0f3B3o1Q7pVHuViMjIZeOo6aYdffP8hwQjSePuQq+U33Ee9ikRYcQ4tSar/Z996vMoEWHkue31wTSiJpV6WYkII4myjFS5rz/FdIAtKpFhxJpJqod3Xp3POEtKJFTf7vdGv2KSeYU4F7cLSoRkJFHJvRqcZDr3CnFrkntdIsVIW3CK8tam/ZEbb1+ckrSUEjlG2jeNUsbvw10PjimZf0KSkfVPLAyZxYHzV4woT0LcgSOk1rylWLu7YpaSv5KR9ftvpin5G0ZWhoyjRKIRU1tvF9XbO5JeSgQaMXU1nyrfJmSmRJ6RVkia3iZ/+CAhaVdcRiXijPRCpoPAex3iSYm06qvq+Q7ZZ0NmVDIxIiYjTyGdkv5vG4SINGIm9/32Kfl4yAg1YuppIlolWxIi0Yip7R2ybTdGizNiC9mMFlZr1O6zA8Iysjsh0oy0ZXf36SNRRsxlU1WRb8RcQpw/EmSkuw4JcGJPkJE6wJBJJVXfxXuMdho5d0YwkmDEBSM2GLGJboRaYxs5d0YSjNgZeVRBjoNXYowkTR6GsWkBRgI3jRG7aYzYTWPEbvqkRqI97sCc1MiwaaYfSRGa/JzPH3k+oyYNciEyZ2j4dE8Ac49vhmXHYdCjyOM+68p3QusXY8owm6uL6LPNqz0RlWTXozv3Haq5R5hXW66XMyakxwRb400p/IcNAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA4FD+AZS0NBe99dfKAAAAAElFTkSuQmCC'
                              "
                            />
                            <v-icon x-large class="img-icon white--text"
                              >mdi-camera</v-icon
                            >
                            <div class="img-loading" v-if="profileLoading">
                              <v-progress-circular
                                indeterminate
                                color="primary"
                                :size="40"
                              ></v-progress-circular>
                              <h6 class="mt-4 grey--text text--darken-2">
                                در حال بارگذاری...
                              </h6>
                            </div>
                          </div>
                          <v-btn
                            class="purple-btn mb-4 ms-4 pa-5"
                            @click="$refs.profileImage.$refs.input.click()"
                          >
                            <v-icon class="img-icon me-2"
                              >mdi-camera-outline</v-icon
                            >بارگذاری تصویر</v-btn
                          >
                          <v-file-input
                            v-show="false"
                            @change="uploadProfile(imageFile)"
                            v-model="imageFile"
                            ref="profileImage"
                          ></v-file-input>
                        </div>
                      </v-col>
                    </v-row>
                    <v-btn
                      class="submit-btn primary-btn mt-4"
                      @click="editService()"
                      :disabled="
                        !(
                          service.name &&
                          service.docPercent >= 0 &&
                          service.docPercent <= 100
                        )
                      "
                      v-if="type == 'clinic'"
                      >ثبت</v-btn
                    >
                    <v-btn
                      class="submit-btn primary-btn mt-4"
                      @click="editService()"
                      :disabled="!service.name"
                      v-else
                      >ثبت</v-btn
                    >
                  </v-card>
                </v-tab-item>
                <!-- اطلاعات و درخواست های ثبت شده-->
                <v-tab-item v-if="type != 'outClinic'">
                  <visits :name="service.name" :type="type" />
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </div>
</template>

<script>
const Visits = () => import("@/components/services/ServiceVisits");

export default {
  props: ["type", "label", "back"],
  components: { Visits },
  data() {
    return {
      tab: null,
      service: {},
      states: [
        { value: "فعال", text: "فعال" },
        { value: "غیرفعال", text: "غیرفعال" },
      ],
      serviceTypes: [
        { value: "خدمات پرستاری", text: "خدمات پرستاری" },
        { value: "ویزیت", text: "ویزیت" },
        { value: "ویزیت تخصصی", text: "ویزیت تخصصی" },
        { value: "خدمات تخصصی", text: "خدمات تخصصی" },
        { value: "خدمات پزشک", text: "خدمات پزشک" },
        { value: "اقلام مصرفی", text: "اقلام مصرفی" },
      ],

      profileLoading: false,
      imageFile: [],
      myImageFile: new Object({
        uploadPercentage: 0,
        image: "",
      }),
      serviceFile: {},
    };
  },
  methods: {
    serviceInfo() {
      //NOTE getting all service info from server
      this.vLoading = true;
      let endpoint;
      if (this.type == "clinic") {
        endpoint = "/clinic/services/serviceId";
      } else if (this.type == "outClinic") {
        endpoint = "/clinic/services/outServiceId";
      } else if (this.type == "dental") {
        endpoint = "/clinic/dentist/serviceId";
      }
      this.$http
        .post(
          this.baseUrl + endpoint,
          {
            outServiceId: this.$route.params.id,
            serviceId: this.$route.params.id,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.service = res.data;
            this.vLoading = false;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            this.vLoading = false;
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.vLoading = false;
        });
    },
    editService() {
      //NOTE sending our changes to server
      this.vLoading = true;
      let endpoint;
      if (this.type == "clinic") {
        endpoint = "/clinic/services/editService";
      } else if (this.type == "outClinic") {
        endpoint = "/clinic/services/editOutServiceId";
      } else if (this.type == "dental") {
        endpoint = "/clinic/dentist/editService";
      }
      this.$http
        .post(
          this.baseUrl + endpoint,
          {
            serviceId: this.$route.params.id,
            outServiceId: this.$route.params.id,
            name: this.service.name,
            cost: this.service.cost ? this.service.cost : "0",
            docPercent: this.service.docPercent ? this.service.docPercent : "0",
            expense: this.service.expense ? this.service.expense : "0",
            protectiveExpense: this.service.protectiveExpense
              ? this.service.protectiveExpense
              : "0",
            status: this.service.status,
            type: this.type == "dental" ? "دندانپزشکی" : this.service.type,
            isSelectableByPatient: this.service.isSelectableByPatient,
            fileId: this.type != "outClinic" ? this.service.fileId ? this.service.fileId : undefined : undefined,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.vLoading = false;
          if (res.status == 200) {
            this.toast(res.data, "success");
            this.$router.push(this.back);
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.vLoading = false;
        });
    },

    uploadProfile(file) {
      if (!file) {
        return;
      }
      let sizeMB = file.size / 1024 / 1024;

      if (sizeMB > 5) {
        this.toast(
          "حجم فایل " + file.name + " نباید از 5 مگابایت بیشتر باشد",
          "error"
        );
        return;
      } else if (file.type != "image/jpeg" && file.type != "image/png") {
        this.toast("فرمت فایل مورد نظر مجاز نمی‌باشد.", "error");
        return;
      } else {
        // NOTE (m-myUpload) to upload files
        this.myImageFile.showProgress = true;
        this.myImageFile.variant = "info";
        this.myImageFile.uploadPercentage = 0;
        this.myImageFile.isUploaded = false;
        this.myImageFile.uploadButDisable = true;
        var formData = new FormData();
        formData.append(file.name, file);
        this.profileLoading = true;
        this.$http
          .post(this.baseUrl + "/clinic/services/upload", formData, {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
            onUploadProgress: ((progressEvent) => {
              this.myImageFile.uploadPercentage =
                Math.round((progressEvent.loaded * 100) / progressEvent.total) -
                1;
            }).bind(this),
          })
          .then((d) => {
            if (d.status == 200) {
              this.myImageFile.isUploaded = true;
              this.myImageFile.uploadPercentage = 100;
              this.myImageFile.striped = false;
              this.myImageFile.variant = "success";
              this.myImageFile.uploadButDisable = false;
              this.service.fileId = d.data.id;
              this.service.fileUrl = d.data.url;
              this.profileLoading = false;
            }
          })
          .catch((err) => {
            console.log(err);
            this.toast(err.response.data, "error");
            this.profileLoading = false;
          });
      }
    },
    percent(value) {
      return value
        ? value < 0 || value > 100
          ? "مقدار سهم باید بین صفر تا صد باشد!"
          : true
        : true;
    },
  },

  mounted() {
    this.serviceInfo();
  },
};
</script>
